



















import { Component, Vue } from "vue-property-decorator";
import { Action } from "@/store/actionType";
@Component({
  components: {}
})
export default class FacebookLogin extends Vue {
  baseUi = Action.Ui;
  private authWindow: Window | null = null;
  private failed: boolean = false;
  private error: string = "";
  private errorDescription: string = "";
  private isBusy: boolean = false;
  private errors: string = "";
  private created() {
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessage.bind(this), false);
    } else {
      (window as any).attachEvent("onmessage", this.handleMessage.bind(this));
    }
  }
  private launchFbLogin() {
    this.authWindow = window.open(
      "https://www.facebook.com/v2.11/dialog/oauth?&response_type=token&display=popup&client_id=2603422739873621&display=popup&redirect_uri=" +
        this.baseUi +
        "/facebook-auth.html&scope=email",
      "",
      "width=600,height=400"
    );
  }
  private handleMessage(event: Event) {
    const message = event as MessageEvent;
    // Only trust messages from the below origin.
    if (message.origin !== this.baseUi) {
      return;
    }
    if (this.authWindow) {
      this.authWindow.close();
    }
    const result = JSON.parse(message.data);
    if (!result.status) {
      this.failed = true;
      this.error = result.error;
      this.errorDescription = result.errorDescription;
    } else if (result.oauth === "facebook") {
      this.failed = false;
      this.isBusy = true;
      this.$emit("fb", { backend: "facebook", token: result.accessToken });
    }
  }
}
