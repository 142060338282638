


































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { JWTStore, UserStore } from "@/store/modules";
import { userInfo, userCreate, userInRole } from "@/store/models/jwt";
import roleList from "@/views/Authscreen/roleList.vue";
import { Select, Radio, Button, Form, FormItem, Row, Col } from "element-ui";

@Component({
  components: {
    Select,
    Radio,
    Button,
    Form,
    FormItem,
    Row,
    Col,
    roleList
  }
})
export default class role extends Vue {
  dialogVisible = false;
  innerVisible: boolean = false;
  selectedIndexofUserInRole = -1;
  duplicate = false;
  selected = false;
  flag = false;
  data: userInRole = {} as userInRole;
  temp: userInRole = {} as userInRole;
  searchString: any = "";

  // get page() {
  //   return JWTStore.Page;
  // }

  // searchClear(event: any) {
  //   if (event == "") {
  //     this.search();
  //   }
  // }

  search() {
    // var filtered = this.saveList.filter(function(el) {
    //   return el != null;
    // });
    // JWTStore.searchUserInRole({
    //   users: this.model,
    //   searchString: this.searchString,
    //   page: 1
    // });
  }
  async handlePrevNextClick(val: any) {
    // var filtered = this.saveList.filter(function(el) {
    //   return el != null;
    // });
    // JWTStore.searchUserInRole({
    //   users: this.model,
    //   searchString: this.searchString,
    //   page: val
    // });
    // this.saveList = [];
  }

  async created() {
    // await JWTStore.searchUserInRole({
    //   users: [],
    //   searchString: this.searchString,
    //   page: 1
    // });
    // await UserStore.loadUsers();
    // await UserStore.loadRoles();
    // await metaStore.loadDisticts();
    // await metaStore.loadProvince();
    // if (this.$route.query.addRole) {
    //   this.addRow();
    // }
  }
  //
  // get users() {
  //   // return metaStore.Users;
  // }
  //
  // get roles() {
  //   // return metaStore.Roles;
  // }
  //
  // get provinces() {
  //   // return metaStore.Province;
  // }
  //
  // get districts() {
  //   // return metaStore.Districts;
  // }
  //
  // get model() {
  //   return JWTStore.UserInRole;
  // }

  // gen(i: Promise<any>) {
  //   i.then(res => {
  //     this.generateEditForm(res);
  //   });
  // }

  // generateEditForm(i: number) {
  //   this.innerVisible = false;
  //   // this.errors.clear();
  //   var a = JWTStore.UserInRole[i];
  //   this.data.id = a["id"];
  //   this.data.user = a["user"];
  //   this.data.role = a["role"];
  //   this.data.username = a["username"];
  //   this.data.rolename = a["rolename"];
  //   this.data.district = a["district"];
  //   this.data.p = this.provinces;
  //   this.data.d = this.districts;
  //
  //   for (const i of this.data.d) {
  //     i.selected = false;
  //   }
  //   for (const i of this.data.p) {
  //     i.selected = false;
  //   }
  //
  //   for (const province of this.data.p) {
  //     for (const district of a.district) {
  //       if (
  //         province.id == district["provinceid"] &&
  //         district["selected"] == true
  //       ) {
  //         province.selected = true;
  //       }
  //     }
  //   }
  //
  //   for (const district of this.data.d) {
  //     for (const d of a.district) {
  //       if (district.name == d["name"] && d["selected"]) {
  //         district.selected = true;
  //       }
  //     }
  //   }
  //
  //   this.data.province_district = [];
  //   for (let province of this.provinces) {
  //     let temp: any = province;
  //     temp["districts"] = this.districts.filter(
  //       x => x.provinceid == province.id
  //     );
  //     this.data.province_district.push(temp);
  //     this.determineSelectAll(province);
  //   }
  //
  //   this.selectedIndexofUserInRole = i;
  //   this.dialogVisible = true;
  // }
  //
  addRow() {
    //   // this.$validator.reset();
    //   // this.errors.clear();
    //   this.data = {} as userInRole;
    //   // this.data.p= JSON.parse(
    //   //   JSON.stringify(this.provinces)
    //   // );
    //   this.data.p = this.provinces;
    //   for (const i of this.data.p) {
    //     i.selected = false;
    //   }
    //   this.data.d = this.districts;
    //   for (const i of this.data.d) {
    //     i.selected = false;
    //   }
    //
    //   this.data.province_district = [];
    //   for (let province of this.provinces) {
    //     let temp: any = province;
    //     temp["districts"] = this.districts.filter(
    //       x => x.provinceid == province.id
    //     );
    //     this.data.province_district.push(temp);
    //     this.determineSelectAll(province);
    //   }
    //
    //   this.selectedIndexofUserInRole = -1;
    //   this.dialogVisible = true;
  }
  //
  async submit() {
    //   // let isvalid = await this.$validator.validateAll();
    //   // if (isvalid) {
    //     var user = this.users.filter(x => x.id === this.data["user"]);
    //     var role = this.roles.filter(x => x.id === this.data["role"]);
    //     var districts = this.districts.filter(x => x.selected);
    //
    //     if (this.selectedIndexofUserInRole < 0) {
    //       for (const i in this.model) {
    //         if (
    //           this.model[i]["username"] == user[0].username &&
    //           this.model[i]["rolename"] == role[0].role
    //         ) {
    //           this.innerVisible = true;
    //           this.dialogVisible = true;
    //           return i as any;
    //         }
    //       }
    //     }
    //     let temp = {
    //       user: this.data["user"],
    //       username: user[0].username,
    //       rolename: role[0].role,
    //       role: this.data["role"],
    //       district: [] as any
    //     };
    //
    //     for (let district of this.districts) {
    //       let dataDistrict: any;
    //       if (this.data.district) {
    //         dataDistrict = this.data.district.filter(
    //           (x: any) => x.name == district.name
    //         );
    //       }
    //       if (dataDistrict && dataDistrict.length > 0) {
    //         temp.district.push({
    //           name: district.name,
    //           selected: district.selected,
    //           provinceid: district.provinceid,
    //           userinroleid: dataDistrict[0]["userinroleid"]
    //         });
    //       } else if (district.selected == true) {
    //         temp.district.push({
    //           name: district.name,
    //           selected: true,
    //           provinceid: district.provinceid,
    //           userinroleid: 0
    //         });
    //       }
    //     }
    //
    //     for (const i in this.model) {
    //       if (
    //         this.model[i]["user"] == this.data["user"] &&
    //         this.model[i]["role"] == this.data["role"]
    //       ) {
    //         JWTStore.pushUserInRoleTemp({ index: Number(i), object: temp });
    //         this.duplicate = true;
    //       }
    //     }
    //     if (!this.duplicate) {
    //       JWTStore.pushUserInRole(temp);
    //     }
    //   // }
    //   this.dialogVisible = false;
    // }
    //
    // selectAll(evt: any, province: any) {
    //   if (evt == true) {
    //     for (let district of province.districts) {
    //       district.selected = true;
    //     }
    //   } else {
    //     for (let district of province.districts) {
    //       district.selected = false;
    //     }
    //   }
    //   province.isIndeterminate = false;
    // }
    // determineSelectAll(province: any) {
    //   let checkedCount = province.districts.filter((x: any) => x.selected).length;
    //   province.selectall = checkedCount === province.districts.length;
    //   province.isIndeterminate =
    //     checkedCount > 0 && checkedCount < province.districts.length;
    // }
    //
    // setUserInRole() {
    //   JWTStore.saveUserInRole(this.model);
    // }
    //
    // close() {
    //   this.innerVisible = false;
    //   this.dialogVisible = false;
  }
}
