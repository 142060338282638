























































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore, UserStore } from "@/store/modules";
import { resetPassword } from "@/store/models/jwt";
import { setJWT, clearJWT } from "@/store/API/api";

@Component({
  components: {
    Input
  }
})
export default class Resetpassword extends Vue {
  payload: resetPassword = {
    uid: "",
    token: "",
    new_password: "",
    re_new_password: ""
  };

  created() {
    this.payload.uid = this.$route.params.uid;
    this.payload.token = this.$route.params.token;
  }

  async submit(e: any) {
    // let isValid = await this.$validator.validateAll();
    // if (isValid) {
    let error: boolean = false;
    await JWTStore.resetPassword(this.payload).catch(err => {
      error = true;
    });
    if (!error) {
      this.$router.push({ name: "changesuccess" }).catch(error => {});
    }
    // JWTStore.logOut();
    // }
  }
}
