





























































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore, UserStore } from "@/store/modules";
import { changePassword } from "@/store/models/jwt";
import { setJWT, clearJWT } from "@/store/API/api";

@Component({
  components: {
    Input
  }
})
export default class Changepassword extends Vue {
  payload: changePassword = {
    current_password: "",
    new_password: "",
    re_new_password: ""
  };

  async submit(e: any) {
    // let isValid = await this.$validator.validateAll();
    // if (isValid) {
    await JWTStore.changePassword(this.payload);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    // clearJWT();
    this.$router.push("/changesuccess").catch(error => {});
    // }
  }
}
