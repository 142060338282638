



























import { Vue, Component } from "vue-property-decorator";
import { JWTStore, UserStore } from "@/store/modules";

@Component
export default class roleList extends Vue {
  get dataList() {
    return JWTStore.UserInRole;
  }

  editForm(i: number) {
    this.$emit("editRole", i);
    console.log(i);
  }

  onDeleteClick(index: number) {
    let user = this.dataList[index].username;
    let userId = this.dataList[index].user;
    let role = this.dataList[index].rolename;
    let roleId = this.dataList[index].role;
    this.$confirm(
      "This will permanently delete the role " +
        role +
        " for " +
        user +
        ". Continue?",
      "Warning",
      {
        confirmButtonText: "Delete",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        await JWTStore.deleteUserInRole({
          userId: userId,
          roleId: roleId,
          index: index
        });
        JWTStore.UserInRole.splice(index, 1);
        console.log("Delete in backend");
      })
      .catch(() => {
        // this.$message({
        //   type: "info",
        //   message: "Delete cancelled"
        // });
      });
  }
}
