





























































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore, UserStore } from "@/store/modules";
import { changeUsername } from "@/store/models/jwt";

@Component({
  components: {
    Input
  }
})
export default class Changeusername extends Vue {
  payload: changeUsername = {
    current_password: "",
    new_username: "",
    re_new_username: ""
  };

  async submit(e: any) {
    // console.log(this.payload);
    // let isValid = await this.$validator.validateAll();
    // if (isValid) {
    await JWTStore.changeUsername(this.payload);
    this.$router.push("/en/profile").catch(error => {});
    // JWTStore.logOut();
    // }
  }
}
