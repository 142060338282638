




































































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore, UserStore } from "@/store/modules";
import { userInfo } from "@/store/models/jwt";
import { setJWT } from "@/store/API/api";

@Component({
  components: {
    Input
  }
})
export default class Login extends Vue {
  payload: userInfo = {
    username: "",
    password: ""
  };

  print() {
    alert("ALERT");
  }

  // created() {
  //   console.log(JWTStore.acc);
  // }

  async login(e: any) {
    // let isValid = await this.$validator.validateAll();
    // if (isValid) {
    console.log(this.payload);
    await JWTStore.getJWT(this.payload);
    await UserStore.getUser();
    // setJWT();
    // await UserStore.getUser();
    this.$router.push("/en").catch(error => {});
    // }
  }
}
