






























import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    Input
  }
})
export default class Changesuccess extends Vue {
  emitGloballogin() {
    eventHub.$emit("loginClick");
  }
}
